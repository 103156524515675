<template>
  <div class="login-page">
    <div class="logo">
      <a href="#">
        <img src="@/assets/images/bionix-b.svg" alt="logo" />
      </a>
    </div>
    <div class="row d-flex justify-center">
      <div class="login-bg">
        <div class="col-md-12">
          <div class="login-box">
            <h2>{{ $t("app-title") }}</h2>
            <div class="form" @keyup.13="onSubmit">
              <div class="form-item">
                <v-text-field
                  :label="$t('label.username')"
                  outlined
                  type="text"
                  :error="user.error"
                  v-model="user.value"
                ></v-text-field>
              </div>
              <div class="form-item">
                <v-text-field
                  :label="$t('label.password')"
                  :error="password.error"
                  outlined
                  type="password"
                  v-model="password.value"
                ></v-text-field>
              </div>
              <hr />
              <v-btn color="primary submit-btn" big block @click="onSubmit">{{
                $t("common.login")
              }}</v-btn>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="copy-text">
      <p style="color: #3f4443">
        {{ `©${year}  ${$t("app-title")}  -  ${$t("org")}` }}
      </p>
    </div>
  </div>
</template>

<script>
import { mapActions } from "vuex";

export default {
  name: "login",
  data() {
    return {
      year: 2021,
      user: {
        value: "",
        error: false,
      },
      password: {
        value: "",
        error: false,
      },
    };
  },
  methods: {
    ...mapActions({
      login: "auth/login",
    }),
    validateForm() {
      let valid = true;
      if (this.user.value === "") {
        this.user.error = true;
        valid = false;
      }

      if (this.password.value === "") {
        this.password.error = true;
        valid = false;
      }

      return valid;
    },
    async onSubmit() {
      if (this.validateForm()) {
        const params = {
          username: this.user.value,
          password: this.password.value,
        };
        this.login(params)
          .then(() => {
            this.$router.push({ name: "pallets" });
          })
          .catch((e) => {
            console.error(e);
          });
      }
    },
  },
};
</script>
